@import 'customicons';
@import "swiper";

a {
  text-decoration: none;
}

.column-action-button-wrapper {
  text-align: right;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiFormHelperText-contained{
  margin-left: 0 !important;
}

.react-checkbox-tree{
  overflow-y: auto;
  height: 40vh;
}

.react-checkbox-tree label{
  display:flex;
  align-items: center;
}

.rct-checkbox {
  padding: 5px;
  display: flex;
  align-items: center;
}

.rct-node-icon{
  padding: 0;
}